/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { LoginPayload, SignUpPayload, VerifyUserPayload } from 'interfaces/authModel';
import axiosI from './index';

export const Type = {
  LOGIN_DATA: 'LOGIN_DATA',
  SIGN_UP_DATA: 'SIGN_UP_DATA',
  FORGOT_PWD: 'FORGOT_PWD',
  LOGOUT_DATA: 'LOGOUT_DATA',
  RESET_PWD: 'RESET_PWD',
  LOADING: 'LOADING',
  VERIFY_USER: 'VERIFY_USER',
  RESEND_CODE: 'RESEND_CODE',
};

export const loginAction =
  (payload: LoginPayload) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const response = await axiosI.post('/auth/login', payload);

      if (response.data.code !== 'UserNotConfirmedException') {
        localStorage.setItem('token', response.data?.loginData?.accessToken);
        localStorage.setItem('idToken', response.data?.loginData?.idToken);
        localStorage.setItem('refreshToken', response.data?.loginData?.refreshToken);
        localStorage.setItem(
          'authDetails',
          JSON.stringify({ ...response.data?.userDetails }),
        );
        dispatch({ type: Type.LOGIN_DATA, payload: response.data });
      } else if (response.data.code !== 'AWS_COGNITO_USER_CREATED') {
        dispatch({ type: Type.VERIFY_USER, payload: response.data });
      }
      return response.data;
    } catch (e: any) {
      console.error(e);
      return e.response.data;
    }

  };

export const forgotPassword =
  (payload: { email: string }) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: Type.LOADING, payload: true });
      const response = await axiosI.post('/auth/forgotPassword', payload);
      dispatch({ type: Type.FORGOT_PWD, payload: response.data });
      return response.data;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      dispatch({ type: Type.LOADING, payload: false });
    }
  };

export const resetPassword =
  (payload: { email: string; otp: string; password: string }) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: Type.LOADING, payload: true });
      const response = await axiosI.post('/auth/reset-password', payload);
      dispatch({ type: Type.RESET_PWD, payload: response.data });
      return response.data;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      dispatch({ type: Type.LOADING, payload: false });
    }
  };

export const signUpAction =
  (payload: SignUpPayload) =>
  async (dispatch: Dispatch): Promise<any> => {
  try {
    const response = await axiosI.post('/auth/signup', payload);
    dispatch({ type: Type.SIGN_UP_DATA, payload: response.data });
    return response.data;
  } catch (error: any) {
    console.error(error.response.data);
    return error.response.data;
  }
  };

export const verifyUser =
  (payload: VerifyUserPayload, userId: string | null) =>
  async (dispatch: Dispatch): Promise<any> => {
    const response = await axiosI.post(`/auth/verify/${userId}`, payload);
    dispatch({ type: Type.VERIFY_USER, payload: response.data });
    return response.data;
  };

export const resendVerificationCode =
  (payload: any) =>
  async (dispatch: Dispatch): Promise<any> => {
    const response = await axiosI.post(`/auth/resend-code`, payload);
    dispatch({ type: Type.RESEND_CODE, payload: response.data });
    return response.data;
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const logoutAction = () => (dispatch: Dispatch) => {
  localStorage.clear();
  dispatch({ type: Type.LOGOUT_DATA });
};
