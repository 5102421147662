import axios from 'axios';
import { logoutAction } from 'actions/authActions';
// eslint-disable-next-line import/no-cycle
import { store } from '../index';

let baseUrl: string = process.env.REACT_APP_DEVELOPMENT_API_URL || '';

if (process.env.NODE_ENV === 'production') {
  baseUrl = process.env.REACT_APP_PRODUCTION_API_URL || '';
}

const axiosI = axios.create({
  baseURL: baseUrl,
});

export const v2AxiosI = axios.create({
  baseURL: baseUrl.replace('v1', 'v2'),
});

axiosI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

v2AxiosI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axiosI.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err?.response?.status === 401) {
      await logoutAction()(store.dispatch);
      window.location.reload();
    }
    return Promise.reject(err);
  },
);

v2AxiosI.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err?.response?.status === 401) {
      await logoutAction()(store.dispatch);
      window.location.reload();
    }
    return Promise.reject(err);
  },
);

export default axiosI;
