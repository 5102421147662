import React, { FC } from 'react';
import { RouteProps, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from 'selectors/authSelector';

const PublicRoute: FC<RouteProps> = ({ component: Component, path, ...rest }) => {
  const token = useSelector(getToken);
  const history = useHistory();
  if (token) {
    history.push('/template');
  }

  if (!Component) return null;

  // eslint-disable-next-line react/jsx-filename-extension
  return <Route path={path} {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
