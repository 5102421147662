import styled from 'styled-components';
import { Input } from 'antd';
import theme from 'theme';

// eslint-disable-next-line import/prefer-default-export
export const AntInput = styled(Input)`
  width: 100%;
  height: 100%;
  min-height: 48px !important;
  input {
    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: ${theme.colors.lightBlueColor};
    }
  }
`;
