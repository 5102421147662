import { handleActions, Action } from 'redux-actions';

import * as AutomationActions from 'actions/automationAction';

export type State = {
  readonly appList: any;
  readonly sourceType: any;
  readonly connectedSourceType: any;
  readonly automationRecords: any;
  readonly loading: boolean;
  readonly activeAutomation: any;
  readonly collaborators: any;
  readonly selectedAutomation: null;
  readonly selectedAutomationHistory: null;
  readonly connectedAppsAutomation: any;
};

const initialState: State = {
  appList: [],
  sourceType: {},
  connectedSourceType: {},
  automationRecords: [],
  loading: false,
  activeAutomation: [],
  selectedAutomation: null,
  collaborators: [],
  selectedAutomationHistory: null,
  connectedAppsAutomation: []
};

export const automationReducer = handleActions<State, any>(
  {
    [AutomationActions.Type.GET_APP_LIST]: (state: State, action: Action<any>) => {
      return {
        ...state,
        appList: action.payload,
      };
    },
    [AutomationActions.Type.GET_SOURCE_TYPE]: (state: State, action: Action<any>) => {
      return {
        ...state,
        sourceType: action.payload.schema,
        automationRecords: action.payload.data,
      };
    },
    [AutomationActions.Type.GET_CONNECTED_SOURCE_TYPE]: (state: State, action: Action<any>) => {
      return {
        ...state,
        connectedSourceType: action.payload.schema,
      };
    },
    [AutomationActions.Type.POST_SOURCE_TYPE_RECORD]: (state: State, action: Action<any>) => {
      return {
        ...state,
        activeAutomation: [...state.activeAutomation, action.payload],
      };
    },
    [AutomationActions.Type.PUT_SOURCE_TYPE_RECORD]: (state: State, action: Action<any>) => {
      if (!state.activeAutomation.length) {
        return state;
      }
      const index = state.activeAutomation.findIndex((automation) => automation._id === action.payload.id);
      if (index !== -1) {
        return {
          ...state,
          activeAutomation: [
            ...state.activeAutomation.slice(0, index),
            { ...action.payload.data },
            ...state.activeAutomation.slice(index + 1),
          ],
          selectedAutomation: null,
        };
      }
      return { ...state };
    },
    [AutomationActions.Type.DELETE_SELECTED_AUTOMATION]: (state: State, action: Action<any>) => {
      if (!state.activeAutomation.length) {
        return state;
      }
      const index = state.activeAutomation.findIndex((automation) => automation._id === action.payload);
      if (index !== -1) {
        return {
          ...state,
          activeAutomation: [...state.activeAutomation.slice(0, index), ...state.activeAutomation.slice(index + 1)],
        };
      }
      return { ...state };
    },
    [AutomationActions.Type.GET_ACTIVE_AUTOMATION]: (state: State, action: Action<any>) => {
      return {
        ...state,
        activeAutomation: action.payload,
      };
    },
    [AutomationActions.Type.GET_SELECTED_AUTOMATION]: (state: State, action: Action<any>) => {
      return {
        ...state,
        selectedAutomation: action.payload.app,
      };
    },
    [AutomationActions.Type.CONNECTED_APPS_AUTOMATION]: (state: State, action: Action<any>) => {
      return {
        ...state,
        connectedAppsAutomation: action.payload,
      };
    },
    [AutomationActions.Type.DELETE_CONNECTED_APPS_AUTOMATION]: (state: State, action: Action<any>) => {
      const index = (state.connectedAppsAutomation || []).findIndex((i: any) => i.sk === action.payload);
      return {
        ...state,
        connectedAppsAutomation: [...state.connectedAppsAutomation.slice(0,index), ...state.connectedAppsAutomation.slice(index+1)],
      };
    },
    [AutomationActions.Type.GET_ACTIVE_AUTOMATION_HISTORY]: (state: State, action: Action<any>) => {
      return {
        ...state,
        selectedAutomationHistory: action.payload,
      };
    },
    [AutomationActions.Type.LOADING]: (state: State, action: Action<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    [AutomationActions.Type.GET_COLLABORATOR]: (state: State, action: Action<any>) => {
      return {
        ...state,
        collaborators: [...state.collaborators, action.payload],
      };
    },
    [AutomationActions.Type.ADD_COLLABORATOR]: (state: State, action: Action<any>) => {
      return {
        ...state,
        collaborators: [...state.collaborators, action.payload],
      };
    },
  },
  initialState,
);
