/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import axiosI from "./index";

export const Type = {
  UPLOAD_CSV_DATA: 'UPLOAD_CSV_DATA',
  LOADING: 'LOADING',
};

export const uploadCSVDataAction =
  (data: any, customerId: string, instanceId) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: Type.LOADING, payload: true });
      const payload = { key: 'workOrder', name: 'dataset', data };
      await axiosI.post(`/setupData/${customerId}/${instanceId}`, payload);
      return true;
    } catch (err) {
      console.error(err);
      return err;
    } finally {
      dispatch({ type: Type.LOADING, payload: false });
    }
  };
