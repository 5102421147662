import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { storeErrorStateAction } from 'actions/utilsAction';

type StateType = {
  hasError: boolean;
  error: string
};

type PropsType = {
  children: ReactElement;
  storeErrorState: (payload) => void;
  authDetails: {
    userId: string;
    email: string;
  };
};

class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { storeErrorState, authDetails = {} } = this.props;
    // You can also log the error to an error reporting service
    this.setState({ error: error.message });
    storeErrorState({ errorHost: 'trackitt-portal', message: error.message, error, errorInfo, ...authDetails });
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return <h1>{error}</h1>;
    }

    return children;
  }
}

const mapStateToProps = (state: any) => ({
  authDetails: state.authReducer?.authDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeErrorState: storeErrorStateAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
