import { handleActions, Action } from 'redux-actions';

import { AppDetailPayload, UsersListPayload, CsvPayload } from '../interfaces/appDetailModel';
import * as AppDetailActions from '../actions/appDetailAction';

export type State = {
  readonly appDetailData: AppDetailPayload[];
  readonly usersListByApp: UsersListPayload[];
  readonly csvDataByAppId: CsvPayload[];
  loading: boolean;
};

type ActionType = {
  apps: AppDetailPayload[];
  users: UsersListPayload[];
  csvDataByAppId: CsvPayload[];
};

const initialState: State = {
  appDetailData: [],
  usersListByApp: [],
  csvDataByAppId: [],
  loading: false,
};

export const appDetailReducer = handleActions<State, ActionType>(
  {
    [AppDetailActions.Type.GET_APP_DETAIL_DATA]: (state: State, action: Action<any>) => {
      return {
        ...state,
        appDetailData: action.payload,
      };
    },
    [AppDetailActions.Type.GET_USERS_BY_APP]: (state: State, action: Action<any>) => {
      const userApps = {
        ...action.payload?.userApps[0],
        users: action.payload?.userApps[0].users.map((userApp) =>
          ({ ...userApp, role: action.payload.roles.find(role => role?.userWithRoleId === userApp?.userId?._id)?.role })
        )
      };
      return {
        ...state,
        usersListByApp: userApps,
      };
    },
    [AppDetailActions.Type.ADD_USER_APP_DATA]: (state: State, action: Action<any>) => {
      return {
        ...state,
        usersListByApp: [...action.payload?.user],
      };
    },
    [AppDetailActions.Type.GET_CSV_DATA]: (state: State, action: Action<any>) => {
      return {
        ...state,
        csvDataByAppId: action.payload,
      };
    },
    [AppDetailActions.Type.EDIT_USER_LIST_CONFIG]: (state: State, action: Action<any>) => {
      return {
        ...state,
        usersListByApp: {
          ...state.usersListByApp,
          ...action.payload
        }
      };
    },
    [AppDetailActions.Type.LOADING]: (state: State, action: Action<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  initialState,
);
