const theme = {
  colors: {
    primaryColor: '#027A9D',
    secondaryColor: '#F08319',
    secondaryLightColor: '#f7dec6',
    primaryLightColor: '#E6F4F8',
    whiteColor: '#FFF',
    blackColor: '#000',
    lightBlueColor: '#94C4D2',
    lightGray: '#F7F9FA',
    textColor: '#81A8B4',
    skyBlue: '#FAFEFF',
    menuBorder: '#C2C9D1',
    cardBackgroundColor: '#FBFEFF',
    lightSkyBlue: '#F1FAFD',
    green: '#4AAF05',
    gray: '#607D8B',
  },
};

export default theme;
