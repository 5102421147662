/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';
import theme from '../../theme';

type BaseProps = Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'as' | 'size'>;
interface ButtonProps extends BaseProps {
  icon?: any;
  disabled?: boolean;
  onClick?: any;
  className?: any;
  style?: any;
  dataCy?: any;
  ghost?: boolean
}

const BaseButton = styled(AntButton)`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.secondaryColor};
  background: ${theme.colors.secondaryColor};
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  height: 43px;
  line-height: 43px;
  color: ${theme.colors.whiteColor};
  justify-content: center;
  &:focus,
  &:hover,
  &:active {
    border-color: ${theme.colors.secondaryColor};
    background: ${theme.colors.secondaryColor};
    color: ${theme.colors.whiteColor};
  }
`;

const IconContainer = styled.span`
  margin-right: 1em;
`;

const Button: React.FC<ButtonProps> = (props) => {
  const { icon, children, disabled = false, onClick, className, style, dataCy, ghost= false } = props;

  return (
    <BaseButton disabled={disabled} onClick={onClick} className={className} style={style} data-cy={dataCy} ghost={ghost}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {children}
    </BaseButton>
  );
};

export default Button;
