import { handleActions, Action } from 'redux-actions';

import * as UserAppActions from 'actions/userAppAction';
import { UserApp } from 'interfaces/userApp';

export type State = {
  readonly userDetailData: UserApp[];
  readonly selectedUserApp: any;
  readonly configurationData: any;
  readonly loading: boolean;
};

const initialState: State = {
  userDetailData: [],
  selectedUserApp: null,
  configurationData: null,
  loading: false,
};

export const userAppReducer = handleActions<State, UserApp[]>(
  {
    [UserAppActions.Type.GET_USER_APP_DATA]: (state: State, action: Action<any>) => {
      return {
        ...state,
        userDetailData: action.payload,
      };
    },
    [UserAppActions.Type.GET_SELECTED_USER_APP_DATA]: (state: State, action: Action<any>) => {
      return {
        ...state,
        selectedUserApp: action.payload,
      };
    },
    [UserAppActions.Type.UPDATE_CONFIGURATION]: (state: State, action: Action<any>) => {
      return {
        ...state,
        configurationData: action.payload,
      };
    },
    [UserAppActions.Type.USER_APPS_LOADING]: (state: State, action: Action<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  initialState,
);
