import { combineReducers } from 'redux';
import { routerReducer as router, RouterState } from 'react-router-redux';

import { appReducer, State as AppState } from './appReducer';
import { authReducer, State as AuthState } from './authReducer';
import { userAppReducer, State as UserAppState } from './userAppReducer';
import { appDetailReducer, State as AppDetailState } from './appDetailReducer';
import { automationReducer, State as AutomationState } from './automationReducer';

export interface RootState {
  router: RouterState;
  appReducer: AppState;
  authReducer: AuthState;
  userAppReducer: UserAppState;
  appDetailReducer: AppDetailState;
  automationReducer: AutomationState;
}
export const rootReducer = combineReducers<RootState>({
  router,
  appReducer: appReducer as any,
  authReducer: authReducer as any,
  userAppReducer: userAppReducer as any,
  appDetailReducer: appDetailReducer as any,
  automationReducer: automationReducer as any,
});
