import { Dispatch } from 'redux';
import axios from 'axios';
import axiosI from './index';

export const Type = {
  GET_ADD_DATA: 'GET_APP_DATA',
  ADD_APP_DATA: 'ADD_APP_DATA',
  STORE_APP_API_DATA: 'STORE_APP_API_DATA',
  LOADING: 'LOADING',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAppDataAction = () => async (dispatch: Dispatch): Promise<any> => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const response = await axiosI.get('/apps');
    dispatch({ type: Type.GET_ADD_DATA, payload: response.data });
    return response.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const addAppData = (data: any, cb: any) => async (dispatch: Dispatch) => {
  try {
    const token = localStorage.getItem('token');
    dispatch({ type: Type.LOADING, payload: true });
    const headers = { Authorization: token, ...data?.headers };
    const res = await axios({ method: data?.method, url: data?.url, data: data?.payload, headers });
    if (res.data.status) {
      dispatch({ type: Type.ADD_APP_DATA, payload: res?.data });
      cb(res);
    }
    return res.data;
  } catch (e: any) {
    console.error(e);
    cb(e.response);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

// eslint-disable-next-line consistent-return
export const storeAppData = (formData) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    dispatch({ type: Type.STORE_APP_API_DATA, payload: formData });
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const getPrivateAppDataAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const response = await axiosI.get('/apps/privateApps');
    dispatch({ type: Type.GET_ADD_DATA, payload: response.data });
    return response.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};
