import { Dispatch } from 'redux';
import axios from 'axios';
import axiosI from './index';

export const Type = {
  GET_APP_LIST: 'GET_APP_LIST',
  GET_SOURCE_TYPE: 'GET_SOURCE_TYPE',
  POST_SOURCE_TYPE_RECORD: 'POST_SOURCE_TYPE_RECORD',
  PUT_SOURCE_TYPE_RECORD: 'PUT_SOURCE_TYPE_RECORD',
  GET_ACTIVE_AUTOMATION: 'GET_ACTIVE_AUTOMATION',
  GET_SELECTED_AUTOMATION: 'GET_SELECTED_AUTOMATION',
  CONNECTED_APPS_AUTOMATION: 'CONNECTED_APPS_AUTOMATION',
  DELETE_CONNECTED_APPS_AUTOMATION: 'DELETE_CONNECTED_APPS_AUTOMATION',
  DELETE_SELECTED_AUTOMATION: 'DELETE_SELECTED_AUTOMATION',
  ADD_COLLABORATOR: 'ADD_COLLABORATOR',
  GET_COLLABORATOR: 'GET_COLLABORATOR',
  GET_ACTIVE_AUTOMATION_HISTORY: 'GET_ACTIVE_AUTOMATION_HISTORY',
  GET_CONNECTED_SOURCE_TYPE: 'GET_CONNECTED_SOURCE_TYPE',
  LOADING: 'LOADING',
};

export const getSourceType = (id) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const res = await axiosI.get(`/appData/${id}`);
    if (res.data.success) {
      dispatch({ type: Type.GET_SOURCE_TYPE, payload: res?.data });
    }
    return res.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const getConnectedSourceType = (id) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const res = await axiosI.get(`/appData/${id}`);
    if (res.data.success) {
      dispatch({ type: Type.GET_CONNECTED_SOURCE_TYPE, payload: res?.data });
    }
    return res.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};


export const postAutomationData = (data: any, cb: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const res = await axiosI.post('automation', data);
    dispatch({ type: Type.POST_SOURCE_TYPE_RECORD, payload: res?.data?.data?.response || {} });
    cb( res?.data?.data);
    return res?.data?.data?.response;
  } catch (e: any) {
    console.error(e);
    cb(e.response.data);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const updateAutomationData = (id: string, data: any, cb: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const res = await axiosI.put(`automation/${id}`, data);
    if (res) {
      dispatch({ type: Type.PUT_SOURCE_TYPE_RECORD, payload: { id, data: res?.data?.data || {} } });
      cb();
    }
    return res?.data?.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const getAutomationData = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    // API call
    const res = await axiosI.get('automation');
    dispatch({ type: Type.GET_ACTIVE_AUTOMATION, payload: res?.data?.apps || {} });
    return res?.data?.apps;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const getAutomationHistory = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    // API call
    const res = await axiosI.get('automationHistory');
    dispatch({ type: Type.GET_ACTIVE_AUTOMATION_HISTORY, payload: res?.data?.apps || {} });
    return res?.data?.apps;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const getConnectedAppsAutomation = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const res = await axiosI.get(`automation/getConnectedAppsAutomation/${id}`);
    if (res.data.status) {
      dispatch({ type: Type.CONNECTED_APPS_AUTOMATION, payload: res?.data?.result || [] });
    }
    return res?.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const deleteConnectedAppsAutomation = (instanceId: string, skId: string, cb: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const res = await axiosI.delete(`automation/getConnectedAppsAutomation/${instanceId}/${skId}`);
    if (res.data.status) {
      dispatch({ type: Type.DELETE_CONNECTED_APPS_AUTOMATION, payload: skId || [] });
    }
    cb(res?.data?.status);
    return res?.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const getAutomationById = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    // API call
    const res = await axiosI.get(`automation/${id}`);
    dispatch({ type: Type.GET_SELECTED_AUTOMATION, payload: res?.data || {} });
    return res?.data?.data;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const deleteAutomationById = (id: string, record, cb?: (status) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    // API call
    await axiosI.delete(`automation/${id}`, { data: { instanceId: record?.instanceId } });
    dispatch({ type: Type.DELETE_SELECTED_AUTOMATION, payload: id });
    if (cb) cb(true);
  } catch (e) {
    console.error(e);
    if (cb) cb(false);
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const addCollaborator = (
  appId: string,
  payload: { collaboratorEmail: string; name: string; ownerId: string },
  cb?: (status, message) => void,
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const response = await axiosI.post(`automation/collaborator/${appId}`, payload);
    if (response.data.status) {
      dispatch({ type: Type.ADD_COLLABORATOR, payload: response?.data?.data });
    }
    if (cb) cb(true, null);
  } catch (err: any) {
    console.error(err.response);
    if (cb) cb(false, err.response?.data?.message || err.response?.data?.msg);
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const getCollaborators = (selectedApp, cb?: (status) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const response = await axiosI.get(`automation/collaborator/${selectedApp}`);
    if (response.data?.status) {
      dispatch({ type: Type.GET_COLLABORATOR, payload: response.data?.data });
    }
    if (cb) cb(true);
  } catch (err) {
    console.error(err);
    if (cb) cb(false);
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const addNewPayloadData = (url, data, cb) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const token = localStorage.getItem('token');
    const response = await axios({ url, method: 'post', data, headers: { Authorization: token } });
    if (response.data?.success) {
      dispatch({ type: Type.GET_COLLABORATOR, payload: data });
    }
    cb(response);
  } catch (err) {
    cb(err);
    console.error(err);
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};

export const testAutomationActions = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    await axiosI.post('automation/test-automation');
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({ type: Type.LOADING, payload: false });
  }
};
