import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import WithLayoutRoute from './withLayoutRoute';
import PublicRoute from './publicRoute';

const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const SignInPage = React.lazy(() => import('pages/SignInPage'));
const ForgotPasswordPage = React.lazy(() => import('pages/ForgotPasswordPage'));
const SignUpPage = React.lazy(() => import('pages/SignUpPage'));
const CreateNewPasswordPage = React.lazy(() => import('pages/CreateNewPassword'));
const CheckMailPage = React.lazy(() => import('pages/CreateNewPassword'));
const MyApps = React.lazy(() => import('pages/MyApps'));
const DetailView = React.lazy(() => import('pages/DetailView'));
const DetailTabView = React.lazy(() => import('pages/DetailTabView'));
const MyDesign = React.lazy(() => import('pages/MyDesign'));
const SettingPage = React.lazy(() => import('pages/SettingPage'));

const HomePage: FC = () => {
  // eslint-disable-next-line react/jsx-filename-extension
  return <Redirect to="/template" />;
};

const Routes: FC = () => {
  return (
    <Router>
      <Switch>
        <WithLayoutRoute path="/" exact component={HomePage} />
        <PublicRoute path="/login" exact component={SignInPage} />
        <PublicRoute path="/forgot-password" exact component={ForgotPasswordPage} />
        <PublicRoute path="/set-new-password" exact component={CreateNewPasswordPage} />
        <PublicRoute path="/check-mail" exact component={CheckMailPage} />
        <PublicRoute path="/sign-up" exact component={SignUpPage} />
        <WithLayoutRoute path="/my-apps" exact component={MyApps} />
        <WithLayoutRoute path="/settings" exact component={SettingPage} />
        <WithLayoutRoute path="/my-apps/:type/:id" exact component={DetailTabView} />
        <WithLayoutRoute path="/template" exact component={DashboardPage} />
        <WithLayoutRoute path="/template/:id" exact component={DetailView} />
        <WithLayoutRoute path="/automation/:type/:automationId?" exact component={MyDesign} />
        <WithLayoutRoute path="*" exact component={DashboardPage} />
      </Switch>
    </Router>
  );
};

export default Routes;
