/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, RefObject } from 'react';
import { AntInput } from './styles';

interface InputProps {
  icon?: JSX.Element;
  label?: string;
  validator?: (value: string) => boolean;
  errorMessage?: string;
  refObj?: RefObject<HTMLInputElement>;
  setError?: boolean;
  prefix?: any;
  className?: string;
  placeholder?: string;
  onChange?: any;
  value?: string;
  type?: string;
  disabled?: boolean;
  onBlur?: any;
  dataCy?: any;
}

const AntInputComponent: FC<InputProps> = (props) => {
  const { dataCy } = props;
  return <AntInput data-cy={dataCy} {...props} />;
};

export default AntInputComponent;
