/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReactComponent as AppSvg } from 'assets/app.svg';
import { ReactComponent as TemplateSvg } from 'assets/template.svg';
import { ReactComponent as DesignSvg } from 'assets/design.svg';
import { logoutAction } from 'actions/authActions';
import { AntSidebar, AntAvatar, AntMenu, AntMenuItem, MenuItemWrapper, Logo } from './styles';

interface LayoutSidebarProps {
  path: string | string[] | undefined | any;
}

const isSelected = (path: string[], selectedMenu: string[]): boolean => {
  return selectedMenu.some((m) => path.includes(m));
};

const LayoutSidebar: FC<LayoutSidebarProps> = ({ path }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email = '' } = JSON.parse(localStorage.getItem('authDetails') || '{}');

  const [selectedMenu, setSelectedMenu] = useState<string[]>(
    typeof path === 'string' || typeof path === 'undefined' ? [path || ''] : path,
  );

  useEffect(() => {
    setSelectedMenu(typeof path === 'string' || typeof path === 'undefined' ? [path || ''] : path);
  }, [path]);

  const onMenuItemClick: any = (event) => {
    const { key } = event;
    switch (key) {
      case 'logout':
        dispatch(logoutAction());
        history.push('/login');
        break;
      default:
        console.error('Something went wrong selecting the option from the menu.');
        break;
    }
  };

  const menu = (
    <Menu onClick={onMenuItemClick}>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  const handleClick = (page) => () => {
    setSelectedMenu([page]);
    history.push(`${page}`);
  };

  return (
    <AntSidebar className="site-layout-background">
      <AntMenu mode="inline" selectedKeys={selectedMenu} style={{ height: '100%', borderRight: 0 }}>
        <div className="m-auto-center">
          <MenuItemWrapper>
            <Logo>T</Logo>
          </MenuItemWrapper>
        </div>
        <div>
          <MenuItemWrapper
            data-cy="my-apps-list"
            className={isSelected(['/my-apps', '/my-apps/:type/:id'], selectedMenu) ? 'selected' : ''}
            onClick={handleClick('/my-apps')}
          >
            <AntMenuItem onClick={() => {}} key="my-apps" icon={<AppSvg />} title="MY APPS" />
          </MenuItemWrapper>
          <MenuItemWrapper
            className={isSelected(['/template', '/template/:id'], selectedMenu) ? 'selected' : ''}
            onClick={handleClick('/template')}
          >
            <AntMenuItem onClick={() => {}} key="templates" icon={<TemplateSvg />} title="TEMPLATES" />
          </MenuItemWrapper>
          <MenuItemWrapper
            data-cy="automation-menu"
            className={
              isSelected(
                [
                  '/automation',
                  '/automation/build-automation',
                  '/automation/active-automation',
                  '/automation/:type/:automationId?',
                ],
                selectedMenu,
              )
                ? 'selected'
                : ''
            }
            onClick={handleClick('/automation/build-automation')}
          >
            <AntMenuItem onClick={() => {}} key="design" icon={<DesignSvg />} title="DESIGN" />
          </MenuItemWrapper>
        </div>
        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
          <AntAvatar size={52}>
            <b>{email?.slice(0, 2).toUpperCase() || ''}</b>
          </AntAvatar>
        </Dropdown>
      </AntMenu>
    </AntSidebar>
  );
};

export default LayoutSidebar;
