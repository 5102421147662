import React, { FC, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import Button from 'components/CustomComponents/Button';
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import { useHistory } from "react-router-dom";
import { icons } from '@trackittapps/trackitt-ui-antd';
import { AntLayoutHeader, RightSideHeader, LeftSideHeader } from './styles';
import AntInputComponent from '../AntInput';
import { getAuthDetails } from "../../selectors/authSelector";
import { AntAvatar } from "../LayoutSidebar/styles";
import { logoutAction } from "../../actions/authActions";

const { SearchSvg, SettingSvg, LogoutSvg } = icons;

const LayoutHeader: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authDetails = useSelector(getAuthDetails);

  const [searchText, setSearchText] = useState('');

  const onSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const onUseAppClick = () => {
    window.location.href = `${process.env.REACT_APP_CORE_UI_URL}`;
  };

  const onMenuItemClick: any = (event) => {
    const { key } = event;
    switch (key) {
      case 'logout':
        dispatch(logoutAction());
        history.push('/login');
        break;
      case 'settings':
        history.push('/settings');
        break;
      default:
        console.error('Something went wrong selecting the option from the menu.');
        break;
    }
  };

  const menu = (
    <Menu onClick={onMenuItemClick}>
      <Menu.Item key="settings" icon={<SettingSvg style={{ width: 14, height: 14, marginRight: 5 }} />}>Settings</Menu.Item>
      <Menu.Item key="logout" icon={<LogoutSvg style={{ width: 14, height: 14, marginRight: 5 }} />}>Logout</Menu.Item>
    </Menu>
  );

  const username = get(authDetails, 'user.userDetails.username');
  const email = get(authDetails, 'user.userDetails.email') || authDetails?.email;
  return (
    <AntLayoutHeader>
      {username || authDetails.username && <LeftSideHeader>Welcome<span className="name">, {username || authDetails.username}</span></LeftSideHeader>}
      <RightSideHeader>
        <div className="d-flex justify-content-between mv-20">
          <div className="d-flex" />
          <AntInputComponent
            className="custom-ant-input box-shadow-search-input"
            prefix={<SearchSvg style={{ height: 14, width: 14 }} color="#94C4D2" />}
            placeholder="Search for products"
            onChange={onSearchChange}
            value={searchText}
          />
        </div>
        <Button onClick={onUseAppClick}>GO TO CONSOLE</Button>
        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
          <AntAvatar size={52}>
            <b>{email?.slice(0, 2).toUpperCase() || ''}</b>
          </AntAvatar>
        </Dropdown>
      </RightSideHeader>
    </AntLayoutHeader>
  );
};

export default LayoutHeader;
