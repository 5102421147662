/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import axiosI from './index';

export const Type = {
  GET_APP_DETAIL_DATA: 'GET_APP_DETAIL_DATA',
  GET_USERS_BY_APP: 'GET_USERS_BY_APP',
  ADD_USER_APP_DATA: 'ADD_USER_APP_DATA',
  GET_CSV_DATA: 'GET_CSV__DATA',
  EDIT_USER_LIST_CONFIG: 'EDIT_USER_LIST_CONFIG',
  LOADING: 'LOADING',
};

export const getAppDataDetailAction = () => async (dispatch: Dispatch) => {
  const response = await axiosI.get('/apps');
  dispatch({ type: Type.GET_APP_DETAIL_DATA, payload: response.data });
  return response.data;
};

export const getUsersByAppId = (appId: string, cb?: () => void) => async (dispatch: Dispatch) => {
  const response = await axiosI.get(`/userApps/users/${appId}`);
  dispatch({ type: Type.GET_USERS_BY_APP, payload: response.data && response.data.data });
  if (cb) cb();
};

export const updateUserConfig = (config, cb?: () => void) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: Type.EDIT_USER_LIST_CONFIG, payload: config });
    if (cb) cb();
  };
};

export const addUserToAppAction =
  (appId: string, payload: { userEmail: string; name: string; adminEmail: string, role: string }, cb?: any) =>
  async (dispatch: Dispatch) => {
  try {
    dispatch({ type: Type.LOADING, payload: true });
    const response = await axiosI.post(`/userApps/user/${appId}`, payload);
    if (cb) cb(response?.data);
    if(response?.data?.status === 200) {
      dispatch({ type: Type.ADD_USER_APP_DATA, payload: response.data });
    }
    dispatch({ type: Type.LOADING, payload: false });
  } catch (e) {
   console.log(e);
    dispatch({ type: Type.LOADING, payload: false });
    if(cb) cb(e);
  }
  };

export const updateUsersByAppId =
  (appId: string, users: { id: string; email: string }, cb?: () => void) => async (dispatch: Dispatch) => {
    await axiosI.put(`/userApps/users/${appId}`, users);
    dispatch({ type: Type.GET_USERS_BY_APP, payload: users });
    if (cb) cb();
  };

export const getUploadedCSVDataAction =
  (customerId: string, instanceId: string) =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch({ type: Type.LOADING, payload: true });
      const response = await axiosI.get(`/setupData/${customerId}/${instanceId}`);
      dispatch({ type: Type.GET_CSV_DATA, payload: response.data.data || [] });
      return response.data;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      dispatch({ type: Type.LOADING, payload: false });
    }
  };
