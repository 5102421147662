import { handleActions, Action } from 'redux-actions';

import { LoginDataPayload, ResetPasswordPayload, ForgotPasswordPayload } from '../interfaces/authModel';
import * as AuthActions from '../actions/authActions';

export type State = {
  readonly authDetails: LoginDataPayload | null;
  readonly token: string | null | undefined;
  readonly forgotPasswordRes: ForgotPasswordPayload;
  readonly resetPasswordRes: ResetPasswordPayload;
  readonly userDetail: any;
  loading: boolean;
};

const authDetails = JSON.parse(localStorage.getItem('authDetails') || '{}') || null;

const initialState: State = {
  authDetails,
  token: localStorage.getItem('token') || '',
  forgotPasswordRes: {},
  resetPasswordRes: {},
  userDetail: {},
  loading: false,
};

export const authReducer = handleActions<State, LoginDataPayload>(
  {
    [AuthActions.Type.LOGIN_DATA]: (state: State, action: Action<any>) => {
      return {
        ...state,
        token: action.payload?.loginData?.accessToken,
        authDetails: {
          id: action.payload?.userDetails?._id,
          email: action.payload?.userDetails?.email,
          user: action.payload,
        },
        userDetail: {
          id: action.payload?.userDetails?._id,
          userName: action.payload?.loginData?.username,
          email: action.payload?.userDetails?.email,
        },
      };
    },
    [AuthActions.Type.LOGOUT_DATA]: (state: State) => {
      return {
        ...state,
        authDetails: null,
        token: null,
        userDetail: null,
      };
    },
    [AuthActions.Type.SIGN_UP_DATA]: (state: State, action: Action<any>) => {
      return {
        ...state,
        userDetail: {
          id: action.payload?.user?._id,
          userName: action.payload?.user?.user?.user?.username,
          email: action.payload?.user?.email,
          user: action.payload,
        },
      };
    },
    [AuthActions.Type.VERIFY_USER]: (state: State, action: Action<any>) => {
      return {
        ...state,
        userDetail: action.payload.userDetails,
      };
    },
    [AuthActions.Type.FORGOT_PWD]: (state: State, action: Action<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    [AuthActions.Type.RESET_PWD]: (state: State, action: Action<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    [AuthActions.Type.LOADING]: (state: State, action: Action<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  initialState,
);
