import styled from 'styled-components';
import { Layout, Avatar, Menu } from 'antd';
import theme from 'theme';

const { Sider } = Layout;
const { Item } = Menu;

export const AntSidebar = styled(Sider)`
  flex: 0 0 72px !important;
  width: 72px !important;
  min-width: 72px !important;
  border: 1px solid #d9d8db;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #fff;
`;

export const AntAvatar = styled(Avatar)`
  background: ${theme.colors.lightBlueColor};
  color: ${theme.colors.blackColor};
  margin: 0 auto;
`;

export const AntMenu = styled(Menu)`
  width: 100%;
  background: ${theme.colors.primaryColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 0;
  padding: 20px 0;
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
`;

export const AntMenuItem = styled(Item)`
  padding: 0 !important;
  text-align: center;
  display: unset !important;
  svg {
    height: 100%;
    width: auto;
    min-height: 30px;
    min-width: 30px !important;
  }
  path {
    fill: ${theme.colors.lightBlueColor};
  }
  rect {
    fill: ${theme.colors.lightBlueColor};
  }
`;

export const MenuItemWrapper = styled.div`
  padding: 10px 0;
  margin-top: 50px !important;
  width: 100% !important;
  text-align: center;
  &:first-child {
    margin-top: 0 !important;
  }
  &:hover,
  &.selected {
    cursor: pointer;
    border-left: 3px solid ${theme.colors.whiteColor};
    path {
      fill: ${theme.colors.whiteColor};
    }
    rect {
      fill: ${theme.colors.whiteColor};
    }
    p {
      color: #9853f5;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin-top: 10px;

    color: #000000;
  }
`;

export const Logo = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 43px;
  letter-spacing: 0.06em;
  display: flex;
  height: 42px;
  width: 42px;
  border-radius: 4px;
  background: ${theme.colors.secondaryColor};
  color: ${theme.colors.whiteColor};
  align-items: center;
  justify-content: center;
`;
