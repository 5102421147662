import { Dispatch } from 'redux';
import axiosI from './index';
import { Type } from './externalAPIAction';

// eslint-disable-next-line import/prefer-default-export
export const storeErrorStateAction =
  (payload: any) =>
  async (dispatch: Dispatch): Promise<boolean> => {
    try {
      dispatch({ type: Type.LOADING, payload: true });
      await axiosI.post('/utils/error', payload);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
