import { handleActions, Action } from 'redux-actions';

import { AppDataPayload } from '../interfaces/appModel';
// eslint-disable-next-line import/no-cycle
import * as AppActions from '../actions/appActions';

export type State = {
  readonly appData: AppDataPayload[];
  readonly appApiData: any;
  readonly loading: boolean;
};

type ActionType = {
  apps: AppDataPayload[];
};

const initialState: State = {
  appData: [],
  appApiData: {},
  loading: false,
};

export const appReducer = handleActions<State, ActionType>(
  {
    [AppActions.Type.GET_ADD_DATA]: (state: State, action: Action<ActionType>) => {
      return {
        ...state,
        appData: action.payload.apps,
      };
    },
    [AppActions.Type.STORE_APP_API_DATA]: (state: State, action: Action<ActionType>) => {
      return {
        ...state,
        appApiData: { ...state.appApiData, ...action.payload },
      };
    },
    [AppActions.Type.LOADING]: (state: State, action: Action<any>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  initialState,
);
