/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import axiosI, { v2AxiosI } from './index';

export const Type = {
  USER_APPS_LOADING: 'USER_APPS_LOADING',
  STORE_APP_DATA: 'STORE_APP_DATA',
  GET_USER_APP_DATA: 'GET_USER_APP_DATA',
  GET_SELECTED_USER_APP_DATA: 'GET_SELECTED_USER_APP_DATA',
  UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
};

export const addAppToUserAction =
  (payload: any) =>
  async (dispatch: Dispatch): Promise<any> => {
    const response = await axiosI.post('/userApps', payload);
    dispatch({ type: Type.STORE_APP_DATA, payload: response.data });
    return response.data;
  };

export const getUserAppsAction =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: Type.USER_APPS_LOADING, payload: true });
    const response = await v2AxiosI.get('/userApps');
    if (response.data?.status) {
      dispatch({ type: Type.GET_USER_APP_DATA, payload: response.data.data });
    }
    dispatch({ type: Type.USER_APPS_LOADING, payload: false });
    return response.data;
  };

export const updateConfiguration =
  (id: any, payload: any) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: Type.USER_APPS_LOADING, payload: true });
      const response = await axiosI.put(`/userApps/config/${id}`, payload);
      dispatch({ type: Type.UPDATE_CONFIGURATION, payload: response.data });
      return response.data;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      dispatch({ type: Type.USER_APPS_LOADING, payload: false });
    }
  };

export const getRoleByUserAppIdAction =
  (id: string, cb) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: Type.USER_APPS_LOADING, payload: true });
    const response = await v2AxiosI.get(`/automation/userApps/${id}`);
    if (response.data?.status && response.data.data) {
      dispatch({ type: Type.GET_SELECTED_USER_APP_DATA, payload: response.data.data });
    }
    dispatch({ type: Type.USER_APPS_LOADING, payload: false });
    if (cb) cb(response.data);
  };

export const removeSelectedUserAppAction =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch({ type: Type.GET_SELECTED_USER_APP_DATA, payload: null });
  };


export const updateApp =
  (id: string, data: any,cb) =>
    async (dispatch: Dispatch): Promise<any> => {
      try {
        dispatch({ type: Type.USER_APPS_LOADING, payload: true });
        const response = await axiosI.put(`/userApps/${id}`, data);
        if (response.data?.status && response.data.data) {
          if (cb) cb(response?.data);
        }
      } catch (e: any) {
        cb(e?.response?.data);
        console.error(e)
      } finally {
        dispatch({ type: Type.USER_APPS_LOADING, payload: false });
      }
};

export const deleteApp =
  (id: string, cb) =>
    async (dispatch: Dispatch): Promise<any> => {
      try {
        dispatch({ type: Type.USER_APPS_LOADING, payload: true });
        const response = await axiosI.delete(`/userApps/${id}`);
        if (response.data?.status) {
          if (cb) cb(response?.data);
        }
      } catch (e: any) {
        cb(e?.response?.data);
        console.error(e)
      } finally {
        dispatch({ type: Type.USER_APPS_LOADING, payload: false });
      }
    };

export const getUserDetail =
  (id: string, cb) =>
    async (dispatch: Dispatch): Promise<any> => {
      try {
        dispatch({ type: Type.USER_APPS_LOADING, payload: true });
        const response = await axiosI.get(`/user/user-details/${id}`);
        if (response.data?.status) {
          if (cb) cb(response?.data);
        }
      } catch (e: any) {
        cb(e?.response?.data);
        console.error(e)
      } finally {
        dispatch({ type: Type.USER_APPS_LOADING, payload: false });
      }
    };

export const updateUserDetail =
  (id: string, payload: any, cb) =>
    async (dispatch: Dispatch): Promise<any> => {
      try {
        dispatch({ type: Type.USER_APPS_LOADING, payload: true });
        const response = await axiosI.put(`/user/user-details/${id}`, payload);
        if (response.data?.status) {
          if (cb) cb(response?.data);
        }
      } catch (e: any) {
        cb(e?.response?.data);
        console.error(e)
      } finally {
        dispatch({ type: Type.USER_APPS_LOADING, payload: false });
      }
    };
