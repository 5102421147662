import React, { FC } from 'react';
import { Route, useHistory, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LayoutHeader from 'components/LayoutHeader';
import LayoutSidebar from 'components/LayoutSidebar';
import { getToken } from 'selectors/authSelector';
import { AntLayout, ContentLayout, AntLayoutContent } from './styles';

const WithLayoutRoute: FC<RouteProps> = ({ component: Component, path, ...rest }) => {
  const token = useSelector(getToken);
  const history = useHistory();
  if (!token) {
    history.push('/login');
  }

  if (!Component) return null;

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <AntLayout>
      <LayoutSidebar path={path} />
      <AntLayout>
        <LayoutHeader />
        <ContentLayout>
          <AntLayoutContent>
            <Route path={path} {...rest} render={(props) => <Component {...props} />} />
          </AntLayoutContent>
        </ContentLayout>
      </AntLayout>
    </AntLayout>
  );
};

export default WithLayoutRoute;
