import styled from 'styled-components';
import { Layout } from 'antd';
import theme from '../theme';

export const AntLayout = styled(Layout)`
  height: 100vh;
  background: #fff;
  overflow: hidden;
`;

export const ContentLayout = styled(Layout)`
  padding-top: 5px;
  background: #d9d8db;
  overflow: auto;
  margin-top: 87px;
`;

export const AntLayoutContent = styled(Layout.Content)`
  min-height: max-content;
  background: ${theme.colors.skyBlue};
`;
