import React, { FC, Suspense } from 'react';
import Routes from 'Route';
import ErrorBoundary from 'components/ErrorBoundary';
import SpinLoader from './components/SpinLoader';
import './App.css';

const App: FC = () => {
  return (
    <Suspense fallback={<SpinLoader />}>
      <div className="App">
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </div>
      <div className="app-error">
        <h1>Sorry, the portal is not accessible in mobile view</h1>
      </div>
    </Suspense>
  );
};

export default App;
