import styled from 'styled-components';
import { Button, Layout } from 'antd';
import theme from 'theme';

const { Header } = Layout;

export const AntLayoutHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.whiteColor};
  height: 87px;
  box-shadow: 0 1px 5px grey;
  align-items: center;
  position: fixed;
  width: calc(100% - 72px);
  z-index: 999;
  padding: 0 25px 0 50px;
  .icon {
    color: ${theme.colors.lightBlueColor};
  }
`;

export const UseApps = styled(Button)`
  text-transform: uppercase;
  background: #8d46ee;
  color: ${theme.colors.whiteColor};
  border-radius: 20px;
`;

export const RightSideHeader = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .custom-ant-input {
    height: 43px;
    width: 300px;
    margin-right: 30px;
    border: none;
  }
  button {
    max-width: 130px;
  }
  .ant-avatar {
    margin: 0 0 0 10px;
  }
`;

export const LeftSideHeader = styled.div`
  font-size: 24px;
  .name {
    font-style: italic;
    color: #027a9d;
  }
`;